import PProviderService from '@/services/api/Provider/PProvider'
import GGoogleService from '@/services/api/General/GGoogle'
import GUserAccountService from '@/services/api/General/GUserAccount'
// import RulesService from '@/services/rules'

export default {
  name: 'v-view-account',
  computed: {

    /**
     * 
     */
    authToken() {
      return this.$store.getters['AuthModule/token'].split('Bearer ').join('')
    },

    /**
     * 
     */
    pProviderZoomAuthorizeLink() {
      return `https://zoom.us/oauth/authorize`
    },

    /**
     * 
     */
    pProviderZoomClientId() {
      return process.env.VUE_APP_ZOOM_CLIENT_ID
    },

    /**
     * 
     */
    pProviderZoomLink() {
      return `${ this.pProviderZoomAuthorizeLink }?response_type=code&client_id=${ this.pProviderZoomClientId }&redirect_uri=${ this.pProviderZoomRedirectLink }&state=${ this.authToken }`
    },

    /**
     * 
     */
    pProviderZoomRedirectLink() {
      return `https://eindustrial.api.sys.dev.innlab.cl/api/zoom-access`
    },

    /**
     * 
     */
    pProviderGoogleLink() {
      return `${ GGoogleService.getLink() }&state=${ this.authToken }`
      // return GGoogleService.getLink()
    }
  },
  data() {
    return {
      busy: {
        representative: false,
      },
      gUserAccount: {},
      pProvider: {},
    }
  },
  methods: {

    /**
     * 
     */
    async getProvider() {
      this.busy.representative = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.busy.representative = false
    },

    /**
     * 
     */
    async getUserAccountByToken() {
      this.busy.representative = true
      this.gUserAccount = await GUserAccountService.getByToken(this.$route.query.state)

      // set auth data
      await this.$store.dispatch('AuthModule/setData', { 
        data: {
          accessToken: this.$route.query.state,
          tokenType: 'Bearer',
          ...this.gUserAccount.data,
          userAccount: undefined,
        } 
      })

      // set user data
      await this.$store.dispatch('UserModule/setData', { 
        data: this.gUserAccount.data
      })

      // get provider
      if (this.gUserAccount.data.profile.code === 'provider') {
        await PProviderService.getById(this.gUserAccount.data.profile.relatedId).then(pProvider => {
          return this.$store.dispatch('ProviderModule/setData', {
            data: pProvider,
          })
        })
      }
      this.getProvider()


      this.$toast.success('Conectado exitosamente.')
      this.busy.representative = false
    },
  },
  mounted() {
    if (this.$provider.id) {
      this.getProvider()
    } else if (this.$route.query.state) {
      //console.log('USER')
      this.getUserAccountByToken()
    }
  },
}